import React, { useCallback } from 'react';
import Cards from 'react-credit-cards';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SwitchInput, TextInput, RadioInput, SelectInput } from '../inputs';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  modes,
} from './creditCardUtils';

import 'react-credit-cards/es/styles-compiled.css';

export function PaymentMethodFields({
  formik,
  initialState = {},
  isOneTimePayment = false,
  mode,
  setMode,
}) {
  const { classes } = useStyles();

  const handleModeChange = useCallback(
    (e) => {
      //reset payment method values
      const { values, setValues } = formik;
      setValues({ ...values, ...initialState });

      setMode(e.target.value);
    },
    [formik, initialState, setMode],
  );

  return (
    <div className={classes.layout}>
      <RadioInput
        value={mode}
        name='mode'
        handleChange={handleModeChange}
        radiosRow
        size='small'
        radios={[
          { label: 'Credit card', value: modes.CREDIT_CARD },
          { label: 'ACH', value: modes.BANK_ACCOUNT },
        ]}
      />

      {mode === modes.CREDIT_CARD && (
        <RenderCreditCardForm
          formik={formik}
          isOneTimePayment={isOneTimePayment}
        />
      )}
      {mode === modes.BANK_ACCOUNT && (
        <RenderBankAccountForm
          formik={formik}
          isOneTimePayment={isOneTimePayment}
        />
      )}
    </div>
  );
}

function RenderCreditCardForm({ formik, isOneTimePayment }) {
  const { classes } = useStyles();

  const {
    setFieldValue,
    values: { number: cardNumber },
  } = formik;

  const handleInputFocus = useCallback(
    (e) => {
      setFieldValue('focus', e.target.name);
    },
    [setFieldValue],
  );

  const handleInputChange = useCallback(
    (e) => {
      let { name, value } = e.target;

      if (e.target.name === 'number') {
        value = formatCreditCardNumber(e.target.value);
      } else if (e.target.name === 'expiry') {
        value = formatExpirationDate(e.target.value);
      } else if (e.target.name === 'cvc') {
        value = formatCVC(e.target.value, { number: cardNumber });
      }

      setFieldValue(name, value);
    },
    [cardNumber, setFieldValue],
  );
  return (
    <div className={classes.cardLayout}>
      <Cards
        cvc={formik.values.cvc}
        expiry={formik.values.expiry}
        focused={formik.values.focus}
        name={formik.values.name}
        number={formik.values.number}
      />
      <div style={{ marginRight: 24, height: 0 }} />
      <Grid container spacing={5} rowSpacing={2}>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='name'
            label='Name on card'
            formikProps={formik}
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            size='small'
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 12,
          }}
        >
          <TextInput
            type='tel'
            name='number'
            label='Card Number'
            formikProps={formik}
            pattern='[\d| ]{16,22}'
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            size='small'
            style={{ width: '100%' }}
            autoComplete='cc-number'
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            type='tel'
            name='expiry'
            label='Expiration'
            formikProps={formik}
            pattern='\d\d/\d\d\d\d'
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            size='small'
            style={{ width: '100%' }}
            autoComplete='cc-exp'
          />
        </Grid>
        <Grid
          style={{ paddingBottom: isOneTimePayment ? 'auto' : 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            type='tel'
            name='cvc'
            label='CVV'
            formikProps={formik}
            pattern='\d{3,4}'
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            size='small'
            style={{ width: '100%' }}
            autoComplete='cc-csc'
          />
        </Grid>
        {!isOneTimePayment && (
          <>
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='zip'
                formikProps={formik}
                label='Zip'
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                size='small'
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <SwitchInput
                name='isDefault'
                formikProps={formik}
                label='Is default'
                className={classes.input}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

function RenderBankAccountForm({ formik, isOneTimePayment }) {
  const { classes } = useStyles();

  return (
    <Grid container spacing={5} rowSpacing={2}>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name='name'
          formikProps={formik}
          label='Name'
          required
          size='small'
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name='phone'
          formikProps={formik}
          label='Phone number'
          required
          size='small'
          format='phone'
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name='accountNumber'
          formikProps={formik}
          label='Account number'
          required
          size='small'
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <SelectInput
          name='accountType'
          formikProps={formik}
          label='Account type'
          required
          size='small'
          options={accountTypeOptions}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: isOneTimePayment ? 24 : 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name='routingNumber'
          formikProps={formik}
          label='Routing number'
          required
          size='small'
        />
      </Grid>
      {!isOneTimePayment && (
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 12,
          }}
        >
          <SwitchInput
            name='isDefault'
            formikProps={formik}
            label='Is default'
            className={classes.input}
          />
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 20px 20px 20px',
  },
  cardLayout: {
    display: 'flex',
  },
  input: {
    width: '100%',
  },
}));

const accountTypeOptions = [
  { id: 'PERSONAL_CHECKING', name: 'Personal checking' },
  { id: 'PERSONAL_SAVINGS', name: 'Personal savings' },
  { id: 'BUSINESS_CHECKING', name: 'Business checking' },
  { id: 'BUSINESS_SAVINGS', name: 'Business savings' },
];
