import { memo } from 'react';
import _get from 'lodash.get';
import { Typography, TypographyProps } from '@mui/material';

import { useStyles } from './fields.styles';
import { AlignType } from '../../../types';
import { stableObject } from '../../../lib';

interface Props {
  typographyProps?: TypographyProps;
  format?: string;
  useTime?: boolean;
  timeZone?: string;
  record?: Record<string, any>;
  convertToLocalTimezone?: boolean;
  timeZoneSource?: string;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  emptyText?: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
}

export const TextField = memo<Props>(
  ({
    className,
    typographyProps: _typographyProps,
    source,
    record: _record,
    emptyText = '--',
    ...rest
  }) => {
    const { classes, cx } = useStyles();
    const record = _record || stableObject;
    const typographyProps = _typographyProps || stableObject;

    const value = _get(record, source);
    return (
      <Typography
        component='span'
        variant='body2'
        className={cx(classes.field, className)}
        {...typographyProps}
      >
        {value !== null && typeof value !== 'string'
          ? JSON.stringify(value)
          : value || emptyText}
      </Typography>
    );
  },
);
