import { authGet, authPost } from '../../lib';
import { showError } from '../features';
import type { AppThunk, NormalizeReturn } from '../types';
import type { IdNamePair } from '../../types/globalTypes';
import {
  AssignUsersRequest,
  AssignedUsersResponse,
  FulfilledServiceAssigneeTaskType,
  GetAssignedUsersRequest,
  ServiceCustomFieldType,
  SystemServiceType,
  EmployeeTrackingPayload,
  AdjustActivityFormType,
} from '../../types/serviceTypes';
import { addResource } from './resource';

interface AssignedUserBasicResponse extends IdNamePair {
  serviceType: SystemServiceType;
  entityID: number;
}

/**
 * Get a list of user tasks
 */
export function getAssignedServicesBasicList(
  taskTypes: Array<FulfilledServiceAssigneeTaskType> = ['SpecialService'],
): AppThunk<Promise<NormalizeReturn<Array<AssignedUserBasicResponse>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<AssignedUserBasicResponse>>([
      '/services/fulfilled/assigned-services/basic',
      { taskTypes },
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function assignUsersToService({
  entityId,
  warehouseId,
  payload,
  serviceType,
}: AssignUsersRequest): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    let url = '';
    switch (serviceType) {
      case 'OrderFulfillment':
        url = `orders/${entityId}/assign-users`;
        break;
      case 'TruckingJob':
        url = `shippingJobs/${entityId}/assign-users`;
        break;
      case 'DrayageJob':
        url = `drayageJobs/${entityId}/assign-users`;
        break;
      case 'Manual':
        url = `/services/fulfilled/${entityId}/assign-users`;
        break;
      case 'Appointment':
        url = `/warehouses/${warehouseId}/appointments/${entityId}/assign-users`;
        break;
      case 'Receipt':
        url = `/receipts/${entityId}/assign-users`;
        break;
      default:
    }
    if (!url) {
      return {
        error: {
          code: 0,
          message: 'Invalid service type',
        },
      };
    }
    const { data, error } = await authPost(url, payload);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getServicesAssigneesPerActivity({
  entityId,
  warehouseId,
  serviceType,
  taskTypes,
}: GetAssignedUsersRequest): AppThunk<
  Promise<NormalizeReturn<AssignedUsersResponse>>
> {
  return async (dispatch) => {
    let url = '';
    switch (serviceType) {
      case 'OrderFulfillment':
        url = `orders/${entityId}/assigned-users`;
        break;
      case 'TruckingJob':
        url = `shippingJobs/${entityId}/assigned-users`;
        break;
      case 'DrayageJob':
        url = `drayageJobs/${entityId}/assigned-users`;
        break;
      case 'Manual':
        url = `/services/fulfilled/${entityId}/assigned-users`;
        break;
      case 'Appointment':
        url = `/warehouses/${warehouseId}/appointments/${entityId}/assigned-users`;
        break;
      case 'Receipt':
        url = `/receipts/${entityId}/assigned-users`;
        break;
      default:
    }
    if (!url) {
      return {
        error: {
          code: 0,
          message: 'Invalid service type',
        },
      };
    }
    const { data, error } = await authGet<AssignedUsersResponse>([
      url,
      { taskTypes },
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

/**
 * Start tracking employee time for a service
 * @returns {normalizeReturn}
 */
export function startEmployeeTracking(
  payload: EmployeeTrackingPayload,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authPost(
      '/services/employee-tracking/start',
      payload,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

/**
 * Stop tracking employee time for a service
 * @returns {normalizeReturn}
 */
export function stopEmployeeTracking(
  payload: EmployeeTrackingPayload,
  showSuccessMessage = true,
  message = 'Successfully paused this task',
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: '/services/employee-tracking/stop',
        payload,
        shouldShowErrorMessage: true,
        message: showSuccessMessage ? message : undefined,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

/**
 * Adjust tracking employee time for a service
 * @returns {normalizeReturn}
 */
export function adjustEmployeeTracking(
  trackingId: string | number,
  payload: Record<string, any>,
  adjustActivityFormType: AdjustActivityFormType,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authPost(
      `/services/employee-tracking/${trackingId}/adjust-${adjustActivityFormType.toLowerCase()}-activity`,
      payload,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function fulfilledServicesPageHeaders(): AppThunk<
  Promise<NormalizeReturn>
> {
  return async (dispatch) => {
    const { data, error } = await authGet('/services/fulfilled/list-header');
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getFulfilledServiceCustomFields(
  fulfilledServiceId: number | string,
): AppThunk<Promise<NormalizeReturn<Array<ServiceCustomFieldType>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<ServiceCustomFieldType>>(
      `services/${fulfilledServiceId}/custom-fields`,
    );
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}
