export const orderStatusStyles = {
  Pending: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  Open: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  Picked: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  AllocatedToPull: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  Queued: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  SentToCustomer: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  Processing: {
    background: '#0FC076',
    color: '#FFFFFF',
  },
  AllocatedToLoad: {
    background: '#0FC076',
    color: '#FFFFFF',
  },
  AwaitingScheduling: {
    background: '#0FC076',
    color: '#FFFFFF',
  },
  Loaded: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Closed: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Completed: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Dispatched: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Hold: {
    background: '#FF7D00',
    color: '#FFFFFF',
  },
  PackedHold: {
    color: '#FFFFFF',
    background: '#0048F8',
  },
  StoredInBin: {
    background: '#FFBD00',
    color: '#FFFFFF',
  },
  Stored: {
    background: '#FFBD00',
    color: '#FFFFFF',
  },
  Archived: {
    background: '#FFBD00',
    color: '#FFFFFF',
  },
  Canceled: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  CompletedWithErrors: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  Returned: {
    background: '#E5E7F5',
  },
  Invalid: {
    background: '#E5E7F5',
  },
  Packed: {
    background: '#0098F7',
    color: '#FFFFFF',
  },
  Scheduled: {
    background: '#0098F7',
    color: '#FFFFFF',
  },
  Notifying: {
    background: '#0098F7',
    color: '#FFFFFF',
  },
  Unknown: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  InTransit: {
    background: '#0FC076',
    color: '#FFFFFF',
  },
  Error: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  Delivered: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Accepted: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  NotYetInSystem: {
    background: '#FF7D00',
    color: '#FFFFFF',
  },
  Cancelled: {
    background: '#FF7D00',
    color: '#FFFFFF',
  },
  QuoteError: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  Paused: {
    background: '#FF99D3',
    color: '#FFFFFF',
  },
  ErrorTracking: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  Lost: {
    background: '#FFBD00',
    color: '#FFFFFF',
  },
  DeliveryAttempt: {
    background: '#0048F8',
    color: '#FFFFFF',
  },
};

export const drayageStatusStyles = {
  Open: {
    color: '#FFFFFF',
    background: '#604FFF',
  },
  SentToCarrier: {
    color: '#FFFFFF',
    background: '#8c80ff',
  },
  CarrierConfirmed: {
    color: '#FFFFFF',
    background: '#a399ff',
  },
  Pending: {
    color: '#FFFFFF',
    background: '#4733ff',
  },
  CustomsHold: {
    color: '#FFFFFF',
    background: '#FFBD00',
  },
  FreightHold: {
    color: '#FFFFFF',
    background: '#FF7D00',
  },
  AvailableDispatch: {
    color: '#FFFFFF',
    background: '#8FD854',
  },
  Dispatched: {
    color: '#FFFFFF',
    background: '#0FC076',
  },
  EnRouteToChassis: {
    color: '#FFFFFF',
    background: '#888EA7',
  },
  PickedUpChassis: {
    color: '#FFFFFF',
    background: '#888EA7',
  },
  EnRouteToPullContainer: {
    color: '#FFFFFF',
    background: '#43485B',
  },
  PulledContainer: {
    color: '#FFFFFF',
    background: '#43485B',
  },
  EnRouteToDropContainer: {
    color: '#000000',
    background: '#ffe499',
  },
  DroppedContainer: {
    color: '#FFFFFF',
    background: '#0048F8',
  },
  DroppedContainerLoaded: {
    color: '#000000',
    background: '#ffe499',
  },
  EnRouteToHookContainer: {
    color: '#FFFFFF',
    background: '#ff99d3',
  },
  HookedContainer: {
    color: '#FFFFFF',
    background: '#ff99d3',
  },
  EnRouteToDeliverContainer: {
    color: '#FFFFFF',
    background: '#FF4FB3',
  },
  DeliveredContainer: {
    color: '#FFFFFF',
    background: '#FF4FB3',
  },
  Empty: {
    color: '#FFFFFF',
    background: '#0048F8',
  },
  EnRouteToReturnContainer: {
    color: '#FFFFFF',
    background: '#4d7fff',
  },
  ReturnedContainer: {
    color: '#FFFFFF',
    background: '#4d7fff',
  },
  EnRouteToReturnChassis: {
    color: '#FFFFFF',
    background: '#1acdff',
  },
  ReturnedChassis: {
    color: '#FFFFFF',
    background: '#1acdff',
  },
  Completed: {
    color: '#FFFFFF',
    background: '#00C4F8',
  },
  Canceled: {
    color: '#FFFFFF',
    background: '#FF4F5B',
  },
  CompletedUninvoicedUnbilled: {
    background: '#0048F8',
    color: '#FFFFFF',
  },
  CompletedUninvoicedBilled: {
    background: '#E5E7F5',
    color: '#000000',
  },
  CompletedInvoicedUnbilled: {
    background: '#43485B',
    color: '#FFFFFF',
  },
  CompletedInvoicedBilled: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
};

export const invoiceStatusStyles = {
  Open: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  Partial: {
    background: '#FF7D00',
    color: '#FFFFFF',
  },
  Void: {
    background: '#FFBD00',
    color: '#FFFFFF',
  },
  Paid: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Error: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
};

export const apReconciliationTypeStyles = {
  NotReconciled: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  AcceptAndPay: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  ChargeCustomer: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
};

export const billingStatusStyles = {
  AwaitingReview: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  OnHold: {
    background: '#FF7D00',
    color: '#FFFFFF',
  },
  Submitted: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Paid: {
    background: '#',
    color: '#FFFFFF',
  },
  Error: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  Void: {
    background: '#FFBD00',
    color: '#FFFFFF',
  },
};

export const truckingJobStyles = {
  Open: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  Pending: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  SentToCustomer: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  AwaitingScheduling: {
    background: '#0FC076',
    color: '#FFFFFF',
  },
  Cancelled: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  QuoteError: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  Scheduled: {
    background: '#0098F7',
    color: '#FFFFFF',
  },
  ClosedUninvoicedUnbilled: {
    background: '#0048F8',
    color: '#FFFFFF',
  },
  ClosedUninvoicedBilled: {
    background: '#E5E7F5',
    color: '#000000',
  },
  ClosedInvoicedUnbilled: {
    background: '#43485B',
    color: '#FFFFFF',
  },
  ClosedInvoicedBilled: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  ScheduledUninvoicedUnbilled: {
    background: '#0048F8',
    color: '#FFFFFF',
  },
  ScheduledUninvoicedBilled: {
    background: '#E5E7F5',
    color: '#000000',
  },
  ScheduledInvoicedUnbilled: {
    background: '#43485B',
    color: '#FFFFFF',
  },
  ScheduledInvoicedBilled: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  NotUsed: {
    background: '#E5E7F5',
  },
};

export const receiptStatusStyles = {
  Pending: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  Arrived: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  Unloaded: {
    background: '#0FC076',
    color: '#FFFFFF',
  },
  Prepping: {
    color: '#FFFFFF',
    background: '#0048F8',
  },
  Prepped: {
    background: '#0098F7',
    color: '#FFFFFF',
  },
  Verified: {
    background: '#FF7D00',
    color: '#FFFFFF',
  },
  Storing: {
    color: '#FFFFFF',
    background: '#FF4FB3',
  },
  Closed: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Paused: {
    background: '#FFBD00',
    color: '#FFFFFF',
  },
  Canceled: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
};

export const moveableUnitStatusStyles = {
  Prepping: {
    color: '#FFFFFF',
    background: '#0048F8',
  },
  Prepped: {
    background: '#0098F7',
    color: '#FFFFFF',
  },
  Verified: {
    background: '#FF7D00',
    color: '#FFFFFF',
  },
  Transporting: {
    color: '#FFFFFF',
    background: '#FF4FB3',
  },
  Transported: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  PuttingAway: {
    background: '#0FC076',
    color: '#FFFFFF',
  },
  Stored: {
    background: '#FFBD00',
    color: '#FFFFFF',
  },
};

export const appointmentStatusStyles = {
  Pending: {
    background: '#FF7D00',
    color: '#FFFFFF',
  },
  Scheduled: {
    background: '#604FFF',
    color: '#FFFFFF',
  },
  Arrived: {
    background: '#8FD854',
    color: '#FFFFFF',
  },
  Confirmed: {
    background: '#0FC076',
    color: '#FFFFFF',
  },
  Processed: {
    background: '#0098F7',
    color: '#FFFFFF',
  },
  Completed: {
    background: '#00C4F8',
    color: '#FFFFFF',
  },
  Canceled: {
    background: '#FF4F5B',
    color: '#FFFFFF',
  },
  Empty: {
    color: '#FFFFFF',
    background: '#0048F8',
  },
};

export const servicesColors = {
  Manual: '#FFBD00',
  OrderFulfillment: '#00C4F8',
  TruckingJob: '#8FD854',
  DrayageJob: '#FF4F5B',
  ResellerJob: '#604FFF',
  LabelPurchasing: '#FF4FB3',
  Receipt: '#0FC076',
  Appointment: '#FF7D00',
};

export const warehousesColors = {
  'NJ Facility': '#00C4F8',
  'CA Facility': '#8FD854',
  'CA Facility (Ward)': '#FF99D3',
  'CA facility (Royal)': '#FF7D00',
  'CA Facility (Easy St)': '#8FD854',
  'Robbinsville NJ Facility': '#FF4F5B',
  'Elizabeth NJ Facility': '#5C4CF5',
  'E Rancho Dominguez Facility': '#FFBD00',
  'Colton CA Facility': '#4733FF',
  default: '#0FC076',
};

export const fulfillmentColors = {
  SmallParcel: '#00C4F8',
  LTL: '#8FD854',
  FTL: '#FF4F5B',
  LabelReceived: '#5C4CF5',
  Pickup: '#FFBD00',
};
