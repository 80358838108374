import { Fragment } from 'react';
import {
  paths,
  generateValidatedParamsUrl,
  appointmentOpenStatuses,
  isProduction,
} from '../../../lib';
import { CalendarTodayIcon, ReceiptLongIcon } from '../../../assets';
import { SidebarItem } from '../SidebarItem';

export function ManagerUserSidebar() {
  return (
    <Fragment>
      {!isProduction && (
        <SidebarItem
          text='Receipts'
          to={generateValidatedParamsUrl(paths.RECEIPTS)}
          icon={ReceiptLongIcon}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.RECEIPTS}`)
          }
        />
      )}
      <SidebarItem
        to={generateValidatedParamsUrl(paths.APPOINTMENTS, undefined, {
          status: appointmentOpenStatuses,
        })}
        text='Appointments'
        icon={CalendarTodayIcon}
        isActive={() =>
          window.location.pathname.startsWith(
            generateValidatedParamsUrl(paths.APPOINTMENTS),
          )
        }
      />
    </Fragment>
  );
}
