import {
  useState,
  useEffect,
  Fragment,
  isValidElement,
  cloneElement,
} from 'react';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { makeStyles } from 'tss-react/mui';
import { Button, Table, Toolbar } from '@mui/material';
import { formatCurrency, getReceiverName } from '../../lib';
import { useDispatch, getServicesAssigneesPerActivity } from '../../state';
import {
  DataGrid,
  TextField,
  FunctionField,
  AssignUserToServiceForm,
  AdjustEmployeeTrackingForm,
  ConfirmStatusIcons,
  ListEditIcon,
  CurrencyField,
  DateField,
} from '..';
import { useGetUsersData } from '../../hooks';

/**
 *
 * @param {object} param
 * @param {string | number} param.entityId
 * @param {string | number} [param.warehouseId]
 * @param {import('../../types').SystemServiceType} param.serviceType
 * @param {boolean} [param.disableAssigning]
 * @param {Array<import('../../types').FulfilledServiceAssigneeTaskType>} [param.taskTypesListFilter]
 * @param {import('../../types').FulfilledServiceAssigneeTaskType} [param.taskTypeToAdd]
 * @param {string} [param.title]
 * @param {Record<string, any>} [param.fieldsToHide]
 * @param {string} [param.addText]
 * @param {import('react').ReactElement} [param.emptyTextComponent]
 * @param {Record<string, any>} [param.defaultData]
 * @param {number} [param.customViewVersion]
 * @param {(hasAssignees: any) => any} [param.getHasAssignees]
 * @param {boolean} [param.hasCustomAdd]
 * @returns
 */
export const ServiceAssignees = ({
  entityId,
  serviceType,
  disableAssigning,
  warehouseId,
  taskTypesListFilter,
  taskTypeToAdd = 'SpecialService',
  title = 'Manual Activity',
  fieldsToHide,
  addText = 'Add task',
  emptyTextComponent,
  defaultData,
  customViewVersion,
  getHasAssignees,
  hasCustomAdd,
}) => {
  const dispatch = useDispatch();
  const { classes, css } = useStyles();
  const { isAdmin } = useGetUsersData();
  const [assigneesData, setAssigneesData] = useState([]);
  const [tasksTotalTime, setTasksTotalTime] = useState('0 sec');
  const [tasksTotalExpense, setTasksTotalExpense] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [viewVersion, setViewVersion] = useState(1);
  const [hasAssignees, setHasAssignees] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useStateWithCallbackInstant(null, (data) =>
    setShowEdit(!!data),
  );
  const [showAdjustModal, setShowAdjustModal] = useState(false);
  const [adjustData, setAdjustData] = useStateWithCallbackInstant(
    null,
    (data) => setShowAdjustModal(!!data),
  );

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getServicesAssigneesPerActivity({
          entityId,
          serviceType,
          warehouseId,
          taskTypes: taskTypesListFilter ?? ['SpecialService'],
        }),
      );
      if (data) {
        const mapped = data.serviceActivities.reduce((acc, cur) => {
          const { assigneeActivities, ...rest } = cur;
          const rv = { ...rest, assigneeActivities };
          const ids = [];
          const rows = assigneeActivities.reduce((accA, curA) => {
            const id = curA.userID + (curA.trackingID ?? 0) + '';
            accA[id] = curA;
            ids.push(id);
            return accA;
          }, {});
          rv.assigneeIds = ids;
          rv.assigneeRows = rows;
          acc.push(rv);
          return acc;
        }, []);
        setAssigneesData(mapped);
        setTasksTotalTime(data.totalDurationDisplay);
        setTasksTotalExpense(data.totalExpense ?? 0);
        setHasAssignees(!!mapped.length);
        typeof getHasAssignees === 'function' &&
          getHasAssignees(!!mapped.length);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, serviceType, viewVersion, customViewVersion]);

  if (hasAssignees === false && isValidElement(emptyTextComponent)) {
    return cloneElement(emptyTextComponent);
  }

  return (
    <div className={css({ marginTop: 40 })}>
      <Toolbar className={classes.actions} disableGutters>
        <h2 className={classes.sectionTitle}>
          <span>{title}</span>
          <span className={classes.spacer}>|</span>
          {`Total time spent ${tasksTotalTime}`}
          {isAdmin && (
            <Fragment>
              <span className={classes.spacer}>|</span>
              <span>{`Total expense ${formatCurrency(
                tasksTotalExpense,
              )}`}</span>
            </Fragment>
          )}
        </h2>
        {isAdmin && !disableAssigning && !hasCustomAdd && (
          <Button
            variant='contained'
            size='large'
            disableElevation
            onClick={() => setShowAdd(true)}
          >
            {addText}
          </Button>
        )}
      </Toolbar>
      {showAdd && (
        <AssignUserToServiceForm
          open={showAdd}
          handleClose={() => setShowAdd(false)}
          setViewVersion={setViewVersion}
          entityId={entityId}
          warehouseId={warehouseId}
          serviceType={serviceType}
          fieldsToHide={fieldsToHide}
          title={title}
          taskType={taskTypeToAdd}
          currentData={defaultData}
        />
      )}
      {showEdit && (
        <AssignUserToServiceForm
          open={showEdit}
          handleClose={() => setEditData(null)}
          setViewVersion={setViewVersion}
          entityId={entityId}
          serviceType={serviceType}
          currentData={{ ...(defaultData ?? {}), ...editData }}
          warehouseId={warehouseId}
          fieldsToHide={fieldsToHide}
          title={title}
          taskType={taskTypeToAdd}
        />
      )}
      {showAdjustModal && (
        <AdjustEmployeeTrackingForm
          adjustActivityFormType='Assignee'
          open={showAdjustModal}
          handleClose={() => setAdjustData(null)}
          setViewVersion={setViewVersion}
          startTime={adjustData?.startTime}
          endTime={adjustData?.endTime}
          isCompleted={adjustData?.isCompleted}
          trackingId={adjustData?.trackingID}
          assigneesId={adjustData?.assigneesID}
        />
      )}
      {assigneesData.map((d, i) => {
        const {
          nickname,
          isCompleted,
          assigneeIds,
          assigneeRows,
          totalDurationDisplay,
          totalExpense,
        } = d;

        return (
          <div
            key={i}
            className={css({
              marginBottom: i < assigneesData.length - 1 ? 40 : 0,
            })}
          >
            <div className={css({ display: 'flex', flexDirection: 'column' })}>
              <span className={classes.subTitle}>{nickname}</span>
              <div className={classes.details}>
                <ConfirmStatusIcons
                  isConfirmed={isCompleted}
                  confirmedText='Completed'
                  unconfirmedText='Not completed'
                />
                <span className={classes.spacer}>|</span>
                {`Total time spent ${totalDurationDisplay}`}
                {isAdmin && (
                  <Fragment>
                    <span className={classes.spacer}>|</span>
                    <span>{`Total expense ${formatCurrency(
                      totalExpense,
                    )}`}</span>
                  </Fragment>
                )}
                {isAdmin && !disableAssigning && !isCompleted && (
                  <ListEditIcon
                    onClick={() => setEditData(d)}
                    className={css({ marginLeft: 5 })}
                  />
                )}
              </div>
            </div>
            <Table>
              <DataGrid
                bulkActionButtons={false}
                ids={assigneeIds}
                rows={assigneeRows}
                hideFooter
              >
                <FunctionField
                  source='userFirstName'
                  label='User'
                  sortable={false}
                  render={(record) => {
                    const { userFirstName, userLastName } = record;
                    return getReceiverName({
                      firstName: userFirstName,
                      lastName: userLastName,
                    });
                  }}
                />
                <TextField
                  source='userTypeDisplay'
                  label='User type'
                  sortable={false}
                />
                <FunctionField
                  source='userFirstName'
                  label='vehicles'
                  sortable={false}
                  render={(record) => {
                    const { assigneeVehicleTypes } = record;
                    if (!assigneeVehicleTypes?.length) {
                      return '--';
                    }
                    return assigneeVehicleTypes
                      .map((a) => a.vehicleTypeDisplay)
                      .join(', ');
                  }}
                />
                <DateField
                  source='startTime'
                  label='Start'
                  sortable={false}
                  format='Pp zzz'
                  useTime
                  headerClassName={classes.noWrap}
                />
                <DateField
                  source='endTime'
                  label='End'
                  sortable={false}
                  format='Pp zzz'
                  useTime
                  headerClassName={classes.noWrap}
                />
                <TextField
                  source='durationDisplay'
                  label='time spent'
                  sortable={false}
                />
                {isAdmin && (
                  <CurrencyField
                    source='expense'
                    label='expense'
                    sortable={false}
                  />
                )}
                {isAdmin && (
                  <FunctionField
                    source=''
                    label=''
                    sortable={false}
                    render={(record) => {
                      const {
                        trackingID,
                        assigneesID,
                        startTime,
                        endTime,
                        isCompleted,
                      } = record;

                      return trackingID ? (
                        <ListEditIcon
                          onClick={() =>
                            setAdjustData({
                              startTime,
                              endTime,
                              isCompleted,
                              trackingID,
                              assigneesID,
                            })
                          }
                        />
                      ) : null;
                    }}
                  />
                )}
              </DataGrid>
            </Table>
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({ name: { ServiceAssignees } })((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: 24,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: 8,
  },
  details: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    letterSpacing: 0,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  spacer: {
    margin: '0px 16px',
  },
}));
