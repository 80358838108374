import { Fragment } from 'react';
import {
  init,
  ErrorBoundary,
  showReportDialog,
  replayIntegration,
  setUser as setSentryUser,
  browserTracingIntegration,
} from '@sentry/react';

const { MODE = 'development', VITE_API_BASE_URL } = import.meta.env;
const __DEV__ = MODE === 'development';

let user;

export function setUser(usr) {
  user = usr;
  setSentryUser({ username: usr?.name, email: usr?.email });
}

init({
  dsn: 'https://d0e73f87f4c24a61b06b4609d8d3fb97@o225001.ingest.sentry.io/5933898',
  integrations: [browserTracingIntegration(), replayIntegration()],
  tracePropagationTargets: [VITE_API_BASE_URL],
  /** Do not send errors in development. */
  beforeSend: __DEV__
    ? function () {
        return null;
      }
    : function (event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        // we need to do this manually because of this issue https://github.com/getsentry/sentry-javascript/issues/3286#issuecomment-786981634
        if (event.exception && event.event_id) {
          showReportDialog({ eventId: event.event_id, user });
        }
        return event;
      },
  environment: MODE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: MODE === 'production' ? 0.7 : 0,
});

export function ErrorSentry({ children }) {
  const fallback = ({ error, componentStack, resetError }) => (
    <Fragment>
      <div>You have encountered an error</div>
      <div>{error.toString()}</div>
      <div>{componentStack}</div>
      <button
        onClick={() => {
          resetError();
        }}
      >
        Click here to reset!
      </button>
    </Fragment>
  );
  return (
    <ErrorBoundary
      // showDialog
      dialogOptions={{ user }}
      fallback={fallback}
    >
      {children}
    </ErrorBoundary>
  );
}
