import { memo, useCallback } from 'react';
import { Snackbar, Slide } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Alert from '@mui/material/Alert';
import { useSelector, useDispatch, hideNotification } from '../../state';
import { useIsMobile } from '../../hooks';

function NotificationTransition(props) {
  const isMobile = useIsMobile();
  return <Slide {...props} direction={isMobile ? 'right' : 'left'} />;
}

export const Notification = memo(() => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const notification = useSelector((state) => state.ui.notification);
  const { message, variant = 'success', duration = 6000 } = notification || {};

  const onClose = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      dispatch(hideNotification());
    },
    [dispatch],
  );

  return (
    <Snackbar
      anchorOrigin={
        isMobile
          ? { vertical: 'top', horizontal: 'left' }
          : { vertical: 'bottom', horizontal: 'right' }
      }
      autoHideDuration={duration}
      open={!!notification}
      onClose={onClose}
      TransitionComponent={NotificationTransition}
      style={{ visibility: notification ? 'visible' : 'hidden' }} // need this to remove it from blocking buttons after being closed
    >
      <Alert
        variant='standard'
        severity={variant}
        onClose={onClose}
        classes={{ root: classes.paper }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
});

const useStyles = makeStyles()((theme) => ({
  paper: {
    borderRadius: 3,
    // backgroundColor: '#FFFFFF',
  },
  icon: {
    color: '#D5D8EC',
    fontSize: 18,
  },
}));
