import { useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  FormGroup,
} from '@mui/material';
import { RadioButtonUncheckedIcon, CheckCircleIcon } from '../../../assets';
import { useStyles } from '../../../themes/muiTheme';

// /**
//  * Material-ui Checkbox with validation
//  * @param {CheckboxInputProps}
//  */
export function CheckboxInput({ checkboxes = [], row = true, ...props }) {
  const { name, formikProps } = props;

  const { touched, errors } = formikProps || {};

  return (
    <FormControl
      variant='standard'
      error={
        touched !== undefined
          ? touched[name] && Boolean(errors[name])
          : undefined
      }
      // className={classes.formControl}
      component='fieldset'
    >
      <FormGroup row={row}>
        {checkboxes.map((c, i) => {
          return <Input key={i} {...props} {...c} />;
        })}
      </FormGroup>
      <FormHelperText>
        {touched !== undefined ? touched[name] && errors[name] : undefined}
      </FormHelperText>
    </FormControl>
  );
}

function Input(props) {
  const { cx } = useStyles();
  const {
    checkboxProps = {},
    labelProps = {},
    className,
    formikProps,
    useCustomValue = false,
    useMobileIcons = false,
  } = props;
  const { onBlur, onChange, name, checked, ...otherProps } = checkboxProps;
  const {
    handleChange: formikHandleChange,
    handleBlur: formikHandleBlur,
    values,
  } = formikProps || {};
  // const { classes } = useStyles();

  const handleBlur = useCallback(
    (e) => {
      formikHandleBlur && formikHandleBlur(e);
      onBlur && onBlur(e);
    },
    [formikHandleBlur, onBlur],
  );

  const handleChange = useCallback(
    (e) => {
      formikHandleChange && !useCustomValue && formikHandleChange(e);
      onChange && onChange(e);
    },
    [formikHandleChange, onChange, useCustomValue],
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={cx(className)}
          onBlur={handleBlur}
          onChange={handleChange}
          color='primary'
          name={name}
          checked={
            formikProps !== undefined && !useCustomValue
              ? values[name]
              : checked
          }
          // helperText={errorMessage || undefined}
          icon={useMobileIcons ? <RadioButtonUncheckedIcon /> : undefined}
          checkedIcon={useMobileIcons ? <CheckCircleIcon /> : undefined}
          sx={useMobileIcons ? mobileColorStyle : undefined}
          {...otherProps}
        />
      }
      {...labelProps}
    />
  );
}

const mobileColorStyle = {
  color: '#A5AABD',
  '&.Mui-checked': {
    color: '#0FC076',
  },
};

// #region Typedefs
// /** @typedef  checkboxes
//  * @type {Array}
//  * @property {Object[] checkboxProps} checkboxes
//  * @property {import("@mui/material/FormControlLabel").FormControlLabelProps} labelProps
//  * @type {import("@mui/material/Checkbox").CheckboxProps} checkboxProps
//  */

// #endregion
