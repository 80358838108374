import { ReactElement, useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  ButtonProps,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@mui/material';
import { Loader } from '../../components';
import { SecondaryButton } from '../SecondaryButton';

interface MobileModalProps
  extends Omit<SwipeableDrawerProps, 'title' | 'onOpen' | 'onClose'> {
  title?: string | ReactElement;
  managerLoginTitle?: string;
  handleClose: () => any;
  disableCancel?: boolean;
  disableSubmit?: boolean;
  callback?: (e?: any) => any;
  cancelBtnText?: string;
  btnText?: string;
  children?: React.ReactNode;
  hasCustomActionBtn?: boolean;
  hideDeleteBtn?: boolean;
  handleDelete?: () => any;
  handleCancel?: () => any;
  submitting?: boolean;
  typeSubmit?: boolean;
  isEdit?: boolean;
  showManagerLogin?: boolean;
  onAdminLogin?: (token: string) => any;
  disableScrollLock?: boolean;
  submitBtnClassName?: string;
  submitBtnProps?: ButtonProps;
}

/**
 * A component that open a form in a styled modal. For forms in an existing modal use `MobileActionForm`.
 * @param param0
 * @returns
 */
export function MobileModalForm(props: MobileModalProps) {
  const { typeSubmit, callback, handleClose, open, title } = props;

  const { classes } = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCloseDrawer = useCallback(() => {
    handleClose();
    setDrawerOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = useCallback(() => setDrawerOpen(true), []);

  useEffect(() => {
    if (open) {
      handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <SwipeableDrawer
        anchor='bottom'
        open={drawerOpen}
        onClose={handleCloseDrawer}
        onOpen={handleOpen}
        PaperProps={{ className: classes.layout }}
        disableSwipeToOpen={false}
        // SwipeAreaProps={{ classes: { root: classes.drawerRoot } }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={classes.puller} />
        {!!title && <h1 className={classes.title}>{title}</h1>}
        {typeSubmit ? (
          <form onSubmit={callback} noValidate autoComplete='off'>
            {<RenderForm {...props} />}
          </form>
        ) : (
          <RenderForm {...props} />
        )}
      </SwipeableDrawer>
    </div>
  );
}

function RenderForm({
  children,
  callback,
  handleCancel,
  btnText = 'Ok',
  cancelBtnText = 'Cancel',
  disableCancel,
  disableSubmit,
  hasCustomActionBtn,
  submitting,
  typeSubmit,
  submitBtnClassName,
  submitBtnProps,
}: MobileModalProps) {
  const { classes, cx } = useStyles();

  return (
    <div>
      {children}
      {submitting && <Loader />}
      {!hasCustomActionBtn && (
        <div className={classes.actions}>
          {!disableCancel && !!handleCancel && (
            <SecondaryButton onClick={handleCancel} fullWidth>
              {cancelBtnText}
            </SecondaryButton>
          )}
          {typeSubmit ? (
            <Button
              type='submit'
              className={cx(classes.submit, submitBtnClassName)}
              variant='contained'
              fullWidth
              disabled={submitting || disableSubmit}
              {...submitBtnProps}
            >
              {btnText}
            </Button>
          ) : (
            <Button
              onClick={callback}
              className={cx(classes.submit, submitBtnClassName)}
              variant='contained'
              fullWidth
              disabled={submitting || disableSubmit}
              {...submitBtnProps}
            >
              {btnText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles({ name: { MobileModalForm } })((theme) => ({
  layout: {
    borderRadius: '32px 32px 0 0',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)',
    padding: '32px 24px 24px 24px ',
  },
  puller: {
    width: 48,
    height: 4,
    borderRadius: 2,
    position: 'absolute',
    top: 16,
    left: 'calc(50% - 15px)',
    backgroundColor: '#D8D8D8',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
    marginBottom: 16,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 16,
  },
  submit: {
    borderRadius: 3,
    fontWeight: 600,
    letterSpacing: 1,
    marginLeft: 16,
  },
}));
