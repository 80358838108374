import { Fragment } from 'react';
import { Chip } from '@mui/material';
import {
  paths,
  pathParams,
  nestedPages,
  reportTypes,
  generateValidatedParamsUrl,
  isProduction,
  appointmentOpenStatuses,
} from '../../../lib';
import {
  DashboardIcon,
  ListAltIcon,
  LocalShippingIcon,
  AttachMoneyIcon,
  MaterialsIcon,
  SettingsIcon,
  AssessmentIcon,
  SummarizeIcon,
  PieChartIcon,
  ReceiptLongIcon,
  CalendarTodayIcon,
} from '../../../assets';
import { useStyles } from '../Sidebar.styles';
import { NestedList } from '../NestedList';
import { SidebarItem } from '../SidebarItem';

export function CustomerServiceSidebar({ handleClearSelectedOrders }) {
  const { classes } = useStyles();
  return (
    <Fragment>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.PROCESS_ORDER)}
        text='Home'
        icon={DashboardIcon}
      />
      <NestedList
        text={
          <span>
            Dashboards{' '}
            <Chip
              size='small'
              label='Beta'
              classes={{ label: classes.label }}
            />
          </span>
        }
        icon={AssessmentIcon}
        id='dashboard'
      >
        <SidebarItem
          to={generateValidatedParamsUrl(paths.MAIN_KPI_DASHBOARD)}
          text='Main KPI’s'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(
              generateValidatedParamsUrl(paths.MAIN_KPI_DASHBOARD),
            )
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.ORDER_FULFILLMENT_DASHBOARD, {
            [pathParams.ORDER_FULFILLMENT_DASHBOARD_TAB]:
              nestedPages.FULFILLMENT_DASHBOARD_SP,
          })}
          text='Order fulfillment'
          isNested
        />
      </NestedList>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.ADMIN_REPORTS, {
          [pathParams.REPORT_TYPE]: nestedPages.P_L_REPORTS,
          [pathParams.NESTED_PAGE]: reportTypes.P_L_FEE_CATEGORY,
        })}
        text='Reporting'
        icon={PieChartIcon}
        isActive={() => window.location.pathname.startsWith('/reports')}
      />

      {!isProduction && (
        <SidebarItem
          text='Receipts'
          to={generateValidatedParamsUrl(paths.RECEIPTS)}
          icon={ReceiptLongIcon}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.RECEIPTS}`)
          }
        />
      )}
      <NestedList text='Orders' icon={ListAltIcon} id='orders' defaultOpen>
        <SidebarItem
          text='Orders'
          to={generateValidatedParamsUrl(paths.ORDERS)}
          isNested
          onClick={handleClearSelectedOrders}
          isActive={() => window.location.pathname.startsWith('/order')}
        />
        <SidebarItem
          text='Batches'
          isNested
          to={generateValidatedParamsUrl(paths.BATCH_LIST)}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.BATCH_LIST}`)
          }
        />
      </NestedList>
      <NestedList text='Shipping' icon={MaterialsIcon} id='shipping'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.GET_SP_ESTIMATE, {
            [pathParams.NESTED_PAGE]: nestedPages.SP_ESTIMATE,
          })}
          text='Browse Rates'
          isNested
          isActive={() =>
            window.location.pathname.startsWith('/small-parcel-estimate')
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.PURCHASED_LABEL)}
          text='Labels & Rates'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.PURCHASED_LABEL}`)
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.SHIPPING_HISTORY)}
          text='Shipping history'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.SHIPPING_HISTORY}`)
          }
        />
      </NestedList>
      <NestedList text='Trucking' icon={LocalShippingIcon} id='trucking'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.DRAYAGE_JOBS, {
            [pathParams.DRAYAGE_JOB_TAB]: nestedPages.DRAYAGE_JOBS_OPENED,
          })}
          text='Drayage'
          isNested
          isActive={() => window.location.pathname.startsWith(`/drayage-jobs`)}
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.TRUCKING_JOBS, {
            [pathParams.TRUCKING_JOB_TAB]: nestedPages.TRUCKING_JOBS_OPENED,
          })}
          text='Quotes & Trucking'
          isNested
          isActive={() => window.location.pathname.startsWith(`/trucking-jobs`)}
        />
      </NestedList>
      <NestedList text='Appointments' icon={CalendarTodayIcon} id='appointment'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.DRIVER_CHECK_IN, undefined, {
            canceled: 'no',
          })}
          text='Driver check-in'
          isNested
        />
        <SidebarItem
          isActive={() => window.location.pathname.includes(`/appointments`)}
          to={generateValidatedParamsUrl(paths.APPOINTMENTS, undefined, {
            status: appointmentOpenStatuses,
          })}
          text='Appointments'
          isNested
        />
      </NestedList>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.FULFILLED_SERVICES)}
        text='Fulfilled services'
        icon={SummarizeIcon}
        isActive={() =>
          window.location.pathname.startsWith(`/${paths.FULFILLED_SERVICES}`)
        }
      />
      <NestedList text='Accounting' icon={AttachMoneyIcon} id='accounting'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.ACCOUNT_RECEIVABLE)}
          text='Invoices'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.ACCOUNT_RECEIVABLE}`)
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.ACCOUNT_PAYABLE)}
          text='Accounts Payable (Audit)'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.ACCOUNT_PAYABLE}`)
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.BILLS)}
          text='Bills'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.BILLS}`)
          }
        />
      </NestedList>
      <NestedList text='Settings' icon={SettingsIcon} id='settings'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.SETTINGS, {
            [pathParams.NESTED_PAGE]: nestedPages.SETTINGS_WORKSTATIONS,
          })}
          text='System'
          isActive={() => window.location.pathname.startsWith('/settings')}
          isNested
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.USERS, {
            [pathParams.NESTED_PAGE]: nestedPages.USERS_INTERNAL,
          })}
          text='Users'
          isActive={() => window.location.pathname.startsWith('/users')}
          isNested
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.MATERIALS, {
            [pathParams.NESTED_PAGE]: nestedPages.VENDORS,
          })}
          text='Materials'
          isActive={() => window.location.pathname.startsWith('/materials')}
          isNested
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.WAREHOUSES)}
          text='Warehouses'
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.WAREHOUSES}`)
          }
          isNested
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.CARRIERS_ACCOUNTS)}
          text='Carriers & accounts'
          isNested
          isActive={() =>
            window.location.pathname.startsWith('/carriers-accounts')
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.TERMINALS)}
          text='Terminals'
          isActive={() => window.location.pathname.startsWith('/terminals')}
          isNested
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.CUSTOMERS)}
          text='Customers'
          isActive={() =>
            !window.location.pathname.startsWith('/users') &&
            window.location.pathname.startsWith('/customer')
          }
          isNested
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.SYSTEM_SERVICES)}
          text='Services'
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.SYSTEM_SERVICES}`)
          }
          isNested
        />
      </NestedList>
    </Fragment>
  );
}
