import {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
  memo,
} from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import { ExpandLessIcon, ExpandMoreIcon } from '../../assets';
import { useSelector, uiSelectors } from '../../state';
import { paths, TRUCKING_JOBS, DRAYAGE_JOBS } from '../../lib';
import { useStyles } from './Sidebar.styles';

interface NestedListProps {
  id: string;
  text: string | React.ReactNode;
  icon: React.JSXElementConstructor<any>;
  defaultOpen?: boolean;
  children?: React.ReactNode;
}

export const NestedList = memo(function NestedList({
  id,
  text,
  icon: Icon,
  defaultOpen,
  children,
}: NestedListProps) {
  const { classes, cx } = useStyles();
  const isSidebarOpen = useSelector(uiSelectors.isSidebarOpen);

  const [open, setOpen] = useState(defaultOpen === true);

  useEffect(() => {
    if (!isSidebarOpen) {
      setOpen(false);
    }
  }, [isSidebarOpen]);

  const handleClick = useCallback(() => {
    setOpen((cur) => !cur);
  }, []);

  const pathName = window.location.pathname; // save in variable to force rerender when it changes

  const isActive = useMemo(() => {
    return nestedActiveRoutes[id]?.some((p) => {
      return pathName.startsWith(p);
    });
  }, [id, pathName]);

  useEffect(() => {
    if (isActive) {
      setOpen(true);
    }
  }, [isActive]);

  return (
    <Fragment>
      <ListItemButton className={classes.ListItem} onClick={handleClick}>
        <ListItemIcon>
          <Icon
            className={cx(classes.icon, {
              [classes.activeIcon]: isActive,
            })}
          />
        </ListItemIcon>
        <ListItemText primary={text} className={classes.liText} />
        {open ? (
          <ExpandLessIcon className={classes.nestedIcon} />
        ) : (
          <ExpandMoreIcon className={classes.nestedIcon} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding className={classes.nestedList}>
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
});

const nestedActiveRoutes = {
  orders: ['/order', `/${paths.BATCH_LIST}`],
  shipping: [
    `/${paths.SHIPPING_HISTORY}`,
    '/small-parcel-estimate',
    `/${paths.PURCHASED_LABEL}`,
    `/${paths.PURCHASE_LABEL}`,
  ],
  trucking: [`/${TRUCKING_JOBS}`, `/${DRAYAGE_JOBS}`],
  appointment: [`/${paths.DRIVER_CHECK_IN}`, `/${paths.APPOINTMENTS}`],
  billing: [`/${paths.BILLS}`, `/${paths.ACCOUNT_PAYABLE}`],
  settings: [
    '/settings',
    '/customer',
    '/users',
    '/materials',
    '/carriers-accounts',
    '/terminals',
    `/${paths.SYSTEM_SERVICES}`,
    `/${paths.WAREHOUSES}`,
  ],
  dashboard: [
    `/${paths.MAIN_KPI_DASHBOARD}`,
    `/${paths.P_L_DASHBOARD}`,
    '/dashboards/order-fulfillment',
    `/${paths.SHIPPING_DASHBOARD}`,
  ],
  accounting: [
    `/${paths.ACCOUNT_RECEIVABLE}`,
    `/${paths.ACCOUNT_PAYABLE}`,
    `/${paths.BILLS}`,
  ],
};
