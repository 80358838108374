import { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SecondaryButton } from '../SecondaryButton';
import { Loader, ManagerLogin } from '../../components';
import { Button, ButtonProps } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  title?: string | ReactElement;
  managerLoginTitle?: string;
  handleCancel?: () => any;
  disableCancel?: boolean;
  disableSubmit?: boolean;
  callback: (e?: any) => any;
  cancelBtnText?: string;
  btnText?: string;
  layoutClassName?: string;
  submitBtnClassName?: string;
  submitBtnProps?: ButtonProps;
  hasCustomActionBtn?: boolean;
  submitting?: boolean;
  typeSubmit?: boolean;
  showManagerLogin?: boolean;
  onAdminLogin?: (token: string) => any;
}

/**
 *
 * @param props This is intended to be used together with `MobileModal` as it does not
 * render in a new modal. For new modals use `MobileModalForm`.
 * @returns
 */
export default function MobileActionForm(props: Props) {
  const { classes } = useStyles();
  const {
    typeSubmit,
    callback,
    managerLoginTitle = 'Manager login required',
    showManagerLogin,
    onAdminLogin,
    handleCancel,
  } = props;
  return showManagerLogin ? (
    <div className={classes.layout}>
      <h1 className={classes.title}>{managerLoginTitle}</h1>
      <ManagerLogin handleNext={onAdminLogin} handleCancel={handleCancel} />
    </div>
  ) : typeSubmit ? (
    <form onSubmit={callback} noValidate autoComplete='off'>
      {<RenderForm {...props} />}
    </form>
  ) : (
    <RenderForm {...props} />
  );
}

function RenderForm({
  title,
  children,
  callback,
  handleCancel,
  btnText = 'Ok',
  cancelBtnText = 'Cancel',
  disableCancel,
  disableSubmit,
  hasCustomActionBtn,
  submitting,
  typeSubmit,
  layoutClassName,
  submitBtnClassName,
  submitBtnProps,
}: Props) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.layout, layoutClassName)}>
      {!!title && <h1 className={classes.title}>{title}</h1>}
      {children}
      {submitting && <Loader />}
      {!hasCustomActionBtn && (
        <div className={classes.actions}>
          {!disableCancel && !!handleCancel && (
            <SecondaryButton onClick={handleCancel} fullWidth>
              {cancelBtnText}
            </SecondaryButton>
          )}
          {typeSubmit ? (
            <Button
              type='submit'
              className={cx(classes.submit, submitBtnClassName)}
              variant='contained'
              fullWidth
              disabled={submitting || disableSubmit}
              {...submitBtnProps}
            >
              {btnText}
            </Button>
          ) : (
            <Button
              onClick={callback}
              className={cx(classes.submit, submitBtnClassName)}
              fullWidth
              disabled={submitting || disableSubmit}
              {...submitBtnProps}
            >
              {btnText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles({ name: { MobileActionForm } })((theme) => ({
  layout: {
    padding: 24,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
    marginBottom: 16,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 16,
  },
  submit: {
    borderRadius: 3,
    fontWeight: 600,
    letterSpacing: 1,
    marginLeft: 16,
  },
}));
