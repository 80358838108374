import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { object, string, date, array, number } from 'yup';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { RadioInput, AutocompleteInput, TextInput } from '../inputs';
import {
  RenderBatchTemplateForm,
  generatePayload as generateFilters,
} from './BatchTemplateForm';
import {
  warehousesSelectors,
  customersSelectors,
  systemSelectors,
  getBasicWarehouses,
  getCustomerBasicList,
  getCarrierList,
  getBatchTemplates,
} from '../../../state';

import {
  authGet,
  generateUrl,
  paths,
  Navigation,
  convertNullFieldsToEmptyString,
} from '../../../lib';
import { useNotify } from '../../../hooks';

const initialState = {
  batchName: '',
  mode: 'template',
  templateId: '',
  customerID: '',
  countryIDs: [],
  stateIDs: [],
  warehouseID: '',
  carrierCode: '',
  carrierServiceCode: '',
  // quantity: '',
  orderCreatedStart: null,
  orderCreatedEnd: null,
};

export function BatchFilterForm({ open, handleClose, editData }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const [state, setState] = useState(initialState);
  const [submitting, setSubmitting] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const batchTemplates = useSelector(
    systemSelectors.batchTemplates,
    shallowEqual,
  );

  const countries = useSelector(systemSelectors.countries, shallowEqual);
  const customers = useSelector(customersSelectors.basicList, shallowEqual);
  const states = useSelector(systemSelectors.states, shallowEqual);
  const warehouses = useSelector(warehousesSelectors.basicList);

  const fetchServices = useCallback(
    async (carrierCode) => {
      if (carrierCode) {
        const { data } = await authGet([
          '/carriers/services/basic',
          { carrierCode },
        ]);
        if (data) {
          setServicesList(data);
        }
      }
    },
    [setServicesList],
  );

  useEffect(() => {
    (async function () {
      await dispatch(getBasicWarehouses());
      await dispatch(getCustomerBasicList());
      await dispatch(getBatchTemplates());

      (async function () {
        const { data } = await dispatch(
          getCarrierList({ includeManualCarriers: false }),
        );
        if (data) {
          const filtered = data.filter((d) => d.id !== 'UNKNOWN');
          setCarriers(filtered);
        }
      })();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function () {
      if (editData) {
        const { stateIDs, countryIDs } = editData;
        setState((cur) => ({
          ...cur,
          ...convertNullFieldsToEmptyString(editData),
          countryIDs: typeof countryIDs == 'string' ? [countryIDs] : countryIDs,
          stateIDs: typeof stateIDs == 'string' ? [stateIDs] : stateIDs,
          mode: 'custom',
        }));
        if (editData.carrierCode) {
          await fetchServices(editData.carrierCode);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = await generatePayload({
        values,
        notify,
        warehouses,
        customers,
        carrierServices: servicesList,
        carriers,
        countries,
        states,
      });
      setSubmitting(false);
      // if (editData) {
      //   refresh();
      // } else {
      Navigation.redirect(generateUrl(paths.BATCH_ORDERS, undefined, payload));
      // }
      handleClose();
    },
    [
      carriers,
      countries,
      customers,
      handleClose,
      notify,
      servicesList,
      states,
      warehouses,
    ],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Create a batch</span>}
      callback={formik.handleSubmit}
      btnText='CONTINUE'
      typeSubmit
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <Grid container spacing={5} rowSpacing={2} className={classes.layout}>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <RadioInput
            formikProps={formik}
            name='mode'
            radiosRow
            size='small'
            title='Batch filters'
            titleClassName={classes.labelTitle}
            radios={[
              { label: 'Template', value: 'template' },
              { label: 'Custom', value: 'custom' },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} rowSpacing={2} className={classes.layout}>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: formik.values.mode === 'custom' ? 6 : 12,
          }}
        >
          <TextInput
            name='batchName'
            label='Batch Name'
            formikProps={formik}
            className={classes.input}
          />
        </Grid>
        {formik.values.mode === 'custom' && (
          <RenderBatchTemplateForm
            carriers={carriers}
            countries={countries}
            customers={customers}
            formik={formik}
            fetchServices={fetchServices}
            servicesList={servicesList}
            states={states}
            warehouses={warehouses}
          />
        )}
        {formik.values.mode === 'template' && (
          <Grid
            size={{
              sm: 12,
            }}
          >
            <AutocompleteInput
              formikProps={formik}
              textFieldProps={{
                name: 'templateId',
                label: 'Select template',
                className: classes.input,
                required: true,
              }}
              autocompleteProps={{
                options: batchTemplates,
              }}
            />
          </Grid>
        )}
      </Grid>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  input: {
    // marginBottom: 16,
    width: '100%',
  },
}));

const schema = object().shape(
  {
    batchName: string().nullable(),
    customerID: string().when('mode', {
      is: 'custom',
      then: string().required('Required'),
    }),
    warehouseID: string().when('mode', {
      is: 'custom',
      then: string().required('Required'),
    }),
    templateId: string()
      .nullable()
      .when('mode', {
        is: 'template',
        then: string().nullable().required('Required'),
      }),
    orderCreatedStart: date()
      .typeError('Invalid Date')
      .nullable()
      .when('orderCreatedEnd', {
        is: (orderCreatedEnd) => !!orderCreatedEnd,
        then: date().typeError('Invalid Date').nullable().required('Required'),
        otherwise: date().typeError('Invalid Date').nullable(),
      }),
    orderCreatedEnd: date()
      .typeError('Invalid Date')
      .nullable()
      .when('orderCreatedStart', {
        is: (orderCreatedStart) => !!orderCreatedStart,
        then: date().typeError('Invalid Date').nullable().required('Required'),
        otherwise: date().typeError('Invalid Date').nullable(),
      }),
    stateIDs: array()
      .of(number().integer('Please enter a valid integer'))
      .nullable(),
    countryIDs: array()
      .of(number().integer('Please enter a valid integer'))
      .nullable(),
  },
  ['orderCreatedStart', 'orderCreatedEnd'],
);

async function generatePayload({
  values,
  notify,
  customers,
  warehouses,
  carriers,
  carrierServices,
  countries,
  states,
}) {
  const { mode, batchName } = values;

  if (mode === 'template') {
    const { data, error } = await authGet(
      `/batches/batch-template/${values.templateId}`,
    );
    if (error) {
      return notify(error.message, 'error');
    } else if (data) {
      const { filtersResponse: { filters, filtersDisplay } = {} } = data;
      return { ...filters, ...filtersDisplay, batchName };
    }
  } else {
    const { filters, filtersDisplay } = generateFilters({
      values,
      customers,
      warehouses,
      carriers,
      carrierServices,
      countries,
      states,
    });
    return { ...filters, ...filtersDisplay, batchName };
  }
}
