import { makeStyles } from 'tss-react/mui';
import { EditIcon } from '../../../assets';
import { IconButton, IconButtonProps, SvgIconProps } from '@mui/material';
import { stableObject } from '../../../lib';

interface CustomIconButtonProps extends IconButtonProps {
  [key: `data-${string}`]: any;
}

interface Props extends SvgIconProps {
  wrapWithButton?: boolean;
  iconButtonProps?: CustomIconButtonProps;
  disabled?: boolean;
}

export function ListEditIcon(props: Props) {
  const { classes, cx } = useStyles();
  const {
    className,
    disabled,
    onClick,
    wrapWithButton,
    iconButtonProps,
    ...rest
  } = props;

  return wrapWithButton ? (
    <IconButton
      size='small'
      {...(iconButtonProps || stableObject)}
      disabled={disabled}
    >
      <EditIcon
        {...rest}
        className={cx(
          classes.editIcon,
          { [classes.disabled]: disabled },
          className,
        )}
      />
    </IconButton>
  ) : (
    <EditIcon
      {...rest}
      onClick={!disabled ? onClick : undefined}
      className={cx(
        classes.editIcon,
        { [classes.disabled]: disabled },
        className,
      )}
    />
  );
}

const useStyles = makeStyles()((theme) => ({
  editIcon: {
    color: '#D5D8EC',
    cursor: 'pointer',
    fontSize: 18,
  },
  disabled: {
    cursor: 'initial',
    opacity: 0.6,
  },
}));
