import {
  authGet,
  authPost,
  generateUrl,
  pathParams,
  paths,
  nestedPages,
} from '../../lib';
import {
  showError,
  addAlert,
  showNotification,
  setUILoading,
} from '../features';
import type { AppThunk, NormalizeReturn } from '../types';

export function getBatchById(
  batchId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`batches/${batchId}`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getBatchAlertById(
  batchId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`batches/${batchId}/alert`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }

    const { hasErrors } = data;
    const pageLink = generateUrl(paths.BATCH_PAGES, {
      [pathParams.BATCH_ID]: batchId,
      [pathParams.NESTED_PAGE]: hasErrors
        ? nestedPages.BATCH_ERRORS
        : nestedPages.BATCH_ORDERS,
    });

    dispatch(addAlert({ id: batchId, value: { ...data, pageLink } }));
    dispatch(showNotification({ message: 'You have an alert' }));
    return { data };
  };
}

export function printBatchLabels(
  batchId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch, getState) => {
    const {
      systemPersist: { machineName },
    } = getState();
    const { data, error } = await authPost(
      [`batches/${batchId}/print-labels`, { machineName }],
      {},
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(showNotification({ message: 'Batch printing' }));
    return { data };
  };
}

export function getBatchSkus(
  customerId: number | string,
  warehouseId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet([
      `batches/basic-skus`,
      { customerId, warehouseId },
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function retryBatch(
  batchId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    dispatch(setUILoading(true));
    const { data, error } = await authPost(
      `orders/processBatch/${batchId}/retryBatch`,
      {},
    );
    dispatch(setUILoading(false));
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(showNotification({ message: 'Batch is processing.' }));
    return { data };
  };
}

export function MarkBatchComplete(
  batchId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    dispatch(setUILoading(true));
    const { data, error } = await authPost(`batches/${batchId}/status`, {});
    dispatch(setUILoading(false));
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(showNotification({ message: 'Batch is processing.' }));
    return { data };
  };
}
