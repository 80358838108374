import React, { memo } from 'react';
import _get from 'lodash.get';
import _cloneDeep from 'lodash.clonedeep';
import { Typography, TypographyProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  billingStatusStyles as _billingStatusStyles,
  invoiceStatusStyles as _invoiceStatusStyles,
  orderStatusStyles as _orderStatusStyles,
  drayageStatusStyles as _drayageStatusStyles,
  truckingJobStyles as _truckingJobStyles,
  receiptStatusStyles as _receiptStatusStyles,
  moveableUnitStatusStyles as _moveableUnitStatusStyles,
  appointmentStatusStyles as _appointmentStatusStyles,
} from '../../../styles/sharedStyles';
import { AlignType, StatusStylesType } from '../../../types';
import { stableObject } from '../../../lib';

// const orderStatusStyles = { ..._orderStatusStyles };
const billingStatusStyles = _cloneDeep(_billingStatusStyles);
const invoiceStatusStyles = _cloneDeep(_invoiceStatusStyles);
const orderStatusStyles = _cloneDeep(_orderStatusStyles);
const drayageStatusStyles = _cloneDeep(_drayageStatusStyles);
const truckingJobStyles = _cloneDeep(_truckingJobStyles);
const receiptStatusStyles = _cloneDeep(_receiptStatusStyles);
const moveableUnitStatusStyles = _cloneDeep(_moveableUnitStatusStyles);
const appointmentStatusStyles = _cloneDeep(_appointmentStatusStyles);

interface Props {
  typographyProps?: TypographyProps;
  record?: Record<string, any>;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
  statusClassName?: string;
  statusClassField?: string;
  stylesType?: StatusStylesType;
  variant?: 'bar' | 'dot';
}

export const StatusField = memo<Props>(
  ({
    className,
    statusClassName,
    statusClassField,
    typographyProps: _typographyProps,
    variant = 'bar',
    source,
    record: _record,
    stylesType = 'order',
    ...rest
  }) => {
    const { classes, cx } = useStyles();
    const { classes: orderClasses } = useOrderStyles();
    const { classes: drayageClasses } = useDrayageStyles();
    const { classes: invoiceClasses } = useInvoiceStyles();
    const { classes: billingClasses } = useBillingStyles();
    const { classes: truckingJobClasses } = useTruckingJobStyles();
    const { classes: receiptClasses } = useReceiptStatusStyles();
    const { classes: moveableUnitClasses } = useMoveableUnitStatusStyles();
    const { classes: appointmentClasses } = useAppointmentStatusStyles();
    const record = _record || stableObject;
    const styles = [
      { [orderClasses[statusClassName ?? '']]: stylesType === 'order' },
      {
        [orderClasses[record[statusClassField ?? '']]]: stylesType === 'order',
      },
      { [drayageClasses[statusClassName ?? '']]: stylesType === 'drayage' },
      {
        [drayageClasses[record[statusClassField ?? '']]]:
          stylesType === 'drayage',
      },
      { [invoiceClasses[statusClassName ?? '']]: stylesType === 'invoice' },
      {
        [invoiceClasses[record[statusClassField ?? '']]]:
          stylesType === 'invoice',
      },
      { [billingClasses[statusClassName ?? '']]: stylesType === 'billing' },
      {
        [billingClasses[record[statusClassField ?? '']]]:
          stylesType === 'billing',
      },
      {
        [truckingJobClasses[statusClassName ?? '']]:
          stylesType === 'truckingJob',
      },
      {
        [truckingJobClasses[record[statusClassField ?? '']]]:
          stylesType === 'truckingJob',
      },
      { [receiptClasses[statusClassName ?? '']]: stylesType === 'receipt' },
      {
        [receiptClasses[record[statusClassField ?? '']]]:
          stylesType === 'receipt',
      },
      {
        [moveableUnitClasses[statusClassName ?? '']]:
          stylesType === 'moveableUnit',
      },
      {
        [moveableUnitClasses[record[statusClassField ?? '']]]:
          stylesType === 'moveableUnit',
      },
      {
        [appointmentClasses[statusClassName ?? '']]:
          stylesType === 'appointment',
      },
      {
        [appointmentClasses[record[statusClassField ?? '']]]:
          stylesType === 'appointment',
      },
    ];
    const typographyProps = _typographyProps || stableObject;

    const value = _get(record, source);

    return value ? (
      <>
        {variant === 'dot' ? (
          <span className={classes.badgeRoot}>
            <Typography
              component='span'
              className={cx(classes.badge, className, ...styles)}
              {...typographyProps}
            />
            <span style={{ paddingLeft: 8 }}>{value}</span>
          </span>
        ) : (
          <span className={cx(classes.root, ...styles)}>
            <Typography
              component='span'
              className={cx(classes.field, className)}
              {...typographyProps}
            >
              {value}
            </Typography>
          </span>
        )}
      </>
    ) : null;
  },
);

const useStyles = makeStyles({ name: { StatusField } })((theme) => ({
  field: {
    whiteSpace: 'nowrap',
  },
  root: {
    padding: '5px 12px',
    borderRadius: 3,
    fontSize: '12px !important',
    fontWeight: 600,
    letterSpacing: 0.86,
  },
  badgeRoot: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    height: 6,
    padding: 0,
    minWidth: 6,
    borderRadius: 4,
    top: '50%',
    left: '-13px',
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
  },
}));

const useOrderStyles = makeStyles({ name: 'order-Styles' })((theme) => ({
  ...orderStatusStyles,
}));

const useDrayageStyles = makeStyles({ name: 'drayage-Styles' })((theme) => ({
  ...drayageStatusStyles,
}));

const useInvoiceStyles = makeStyles({ name: 'invoice-Styles' })((theme) => ({
  ...invoiceStatusStyles,
}));

const useBillingStyles = makeStyles({ name: 'billing-Styles' })((theme) => ({
  ...billingStatusStyles,
}));

const useTruckingJobStyles = makeStyles({ name: 'trucking-job-Styles' })(
  (theme) => ({
    ...truckingJobStyles,
  }),
);

const useReceiptStatusStyles = makeStyles({ name: 'receipt-Styles' })(
  (theme) => ({
    ...receiptStatusStyles,
  }),
);

const useMoveableUnitStatusStyles = makeStyles({ name: 'mu-Styles' })(
  (theme) => ({
    ...moveableUnitStatusStyles,
  }),
);

const useAppointmentStatusStyles = makeStyles({ name: 'appointment-Styles' })(
  (theme) => ({
    ...appointmentStatusStyles,
  }),
);
