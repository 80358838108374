import { memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import {
  Button,
  IconButton,
  ButtonProps,
  IconButtonProps,
} from '@mui/material';

type ButtonLinkProps = LinkProps & ButtonProps;

type IconButtonLinkProps = LinkProps & IconButtonProps;

export const ButtonLink = memo((props: ButtonLinkProps) => {
  const { children, ...rest } = props;
  return (
    <Button component={Link} {...rest}>
      {children}
    </Button>
  );
});

export const IconButtonLink = memo((props: IconButtonLinkProps) => {
  const { children, ...rest } = props;
  return (
    <IconButton component={Link} {...rest}>
      {children}
    </IconButton>
  );
});
